import type { IPremiers } from '~/types/movies';
import type { RouteLocationRaw } from 'vue-router';

export const usePremiers = (movie: IPremiers) => {
  const route = computed((): RouteLocationRaw => ({ name: 'movie-slug', params: { slug: movie.slug } }));
  return {
    activeImg: movie.world_premier_poster || movie.image,
    inactiveImg: movie.poster_middle || movie.image,
    name: movie.name,
    rating: {
      flex: movie.rating,
      kp: movie.kp,
      imdb: movie.imdb,
    },
    film: {
      year: movie.film.year,
      genre: movie.film.genre,
      duration: '',
    },
    route,
  };
};
