<script lang="ts" setup>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import type { SwiperOptions, Swiper as ISwiper } from 'swiper/types';
  import type { IBanner, IPremiers } from '~/types/movies';
  import CarouselSliderItem from '~/components/sliders/carousel/CarouselSliderItem.vue';
  import VButton from '~/components/ui/VButton.vue';
  import Carousel from '~/components/sliders/carousel/animation';
  import type { ExtractPublicPropTypes } from 'vue';

  interface IProps {
    items: IPremiers[];
    settings?: SwiperOptions;
  }
  const props = defineProps<IProps>();

  const modules = [Carousel];
  const options: SwiperOptions = {
    modules,
    speed: 400,
    initialSlide: 0,
    effect: 'carousel',
    carouselEffect: {
      opacityStep: 0.33,
      scaleStep: 0.2,
      sideSlides: 4,
    },
    loop: true,
    slidesPerView: 'auto',
    // loopAdditionalSlides: 2,
    centeredSlides: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
  };
  const settings: ExtractPublicPropTypes<typeof Swiper> = { ...options, ...props.settings };

  const swiperInstance = ref<ISwiper | null>(null);
  const onSwiper = (instance: ISwiper) => {
    swiperInstance.value = instance;
  };
  const slideNext = () => {
    swiperInstance.value!.slideNext(500);
  };
  const slidePrev = () => {
    swiperInstance.value!.slidePrev(500);
  };
</script>

<template>
  <div class="carousel-slider">
    <div class="carousel-slider__control left">
      <v-button
        appearance="outline"
        icon="arrow-left-btn"
        color="white"
        title="Стрелка влево"
        rounded
        @click="slidePrev"
      />
    </div>
    <div class="carousel-slider__control right">
      <v-button
        appearance="outline"
        icon="arrow-right-btn"
        color="white"
        title="Стрелка вправо"
        rounded
        @click="slideNext"
      />
    </div>
    <swiper v-bind="settings" @swiper="onSwiper">
      <swiper-slide v-for="(slide, index) in props.items" :key="index" v-slot="{ isActive, isPrev, isNext }">
        <carousel-slider-item
          :slide="slide"
          :active="isActive"
          :prev="isPrev"
          :next="isNext"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="scss">
  .carousel-slider {
    position: relative;
    .swiper {
      position: relative;
      display: grid;
      //height: 580px;
      align-items: center;
      .swiper-wrapper {
        height: auto;
      }
      .swiper-slide {
        width: 580px;
        height: 620px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__control {
      position: absolute;
      z-index: 2;
      height: 100%;
      display: flex;
      align-items: center;
      &.left {
        left: -4rem;
      }
      &.right {
        right: -4rem;
      }
    }
    @media (max-width: $laptop-small) {
      &__control {
        display: none;
      }
    }
    @media (max-width: $retina) {
      .swiper {
        .swiper-slide {
          width: 296px;
          height: 344px;
        }
      }
    }
  }
</style>
